<template>
    <ViewTitle title="磊山e管家後台 | 客戶上傳保單" />
    <UploadFilter
        @show-data-table="showDataTable"
        v-model:filterPayloadProp="filterPayload"
        v-model:filterLogProp="filterLog"
        :isLoading="isLoading"
        :isFocusRow="true"
    />
    <UploadTable
        :listData="listData"
        :filterLog="filterLog"
        :isLoading="isLoading"
    />
</template>
<script>
import ViewTitle from '@/components/ViewTitle.vue'
import UploadFilter from '@/containers/ctw/UploadFilter.vue'
import UploadTable from '@/containers/ctw/UploadTable.vue'
import { getCTWInsuranceListAPI } from '@/assets/javascripts/api'

export default {
    name: 'CtwUpload',
    components: {
        ViewTitle,
        UploadFilter,
        UploadTable
    },
    methods: {
        async showDataTable() {
            this.$showLoading()
            try {
                this.isLoading = true
                const res = await this.$getResponse(
                    this.filterPayload,
                    'skip',
                    getCTWInsuranceListAPI
                )
                this.listData = res.data.insurances
            } catch (e) {
                this.listData = []
                this.$showErrorMessage(
                    '載入時出現錯誤，請稍後再試',
                    '若持續出現此問題，請聯繫資訊客服'
                )
            } finally {
                this.isLoading = false
                this.$hideLoading()
            }
        }
    },
    data() {
        return {
            filterLog: {},
            filterPayload: {
                titleType: 'all',
                manCodes: [],
                userName: '',
                effectDate: ''
            },
            isLoading: false,
            listData: []
        }
    }
}
</script>
