<template>
    <FilterModal @reset="resetFilter" @filter="applyFilter">
        <template #filters>
            <div v-show="false">
                <TitleTypeAccountFilter
                    v-model:titleType="filterPayload.titleType"
                    v-model:manCodes="filterPayload.manCodes"
                    :hasSelectedALL="true"
                    ref="titleTypeAccountFilter"
                />
            </div>
            <Textbox
                name="userName"
                :title="filterTitles.userName"
                type="text"
                v-model="filterPayload.userName"
                placeholder="請輸入要保人/被保人"
            />
            <Textbox
                name="effectDate"
                type="date"
                max="today"
                :title="filterTitles.effectDate"
                v-model="filterPayload.effectDate"
            />
        </template>
    </FilterModal>
</template>
<script>
import TitleTypeAccountFilter from '@/containers/TitleTypeAccountFilter.vue'
import FilterModal from '@/containers/FilterModal.vue'
import Textbox from '@/components/Textbox.vue'
import { uploadTitle } from '@/assets/javascripts/ctw/setting'
import filterDefault from '@/assets/json/filterDefault.json'

export default {
    name: 'CtwUploadFilter',
    components: {
        TitleTypeAccountFilter,
        FilterModal,
        Textbox
    },
    props: {
        filterPayloadProp: {
            type: Object,
            default: function () {
                return {}
            }
        },
        filterLogProp: {
            type: Object,
            default: function () {
                return {}
            }
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        resetFilter() {
            this.filterPayload = { ...filterDefault.upload }
            this.$refs.titleTypeAccountFilter.resetFilter()
            this.filterPayload = this.$getStorePayload(this.filterPayload)
            this.$emit('update:filterPayloadProp', this.filterPayload)
        },
        applyFilter() {
            if (
                !this.$isSameValue(
                    this.perviousFilterPayload,
                    this.filterPayload
                )
            ) {
                this.perviousFilterPayload = this.$cloneDeepValue(
                    this.filterPayload
                )
                this.$emit('show-data-table')
            }
            this.filterLog = this.$getFilterLog(this.filterPayload)
            this.hasCrossFilter = false
        }
    },
    watch: {
        filterPayload: {
            handler() {
                this.$emit('update:filterPayloadProp', this.filterPayload)
            },
            deep: true
        },
        filterLog: {
            handler() {
                this.$emit('update:filterLogProp', this.filterLog)
            },
            deep: true
        }
    },
    data() {
        return {
            filterPayload: this.filterPayloadProp,
            filterLog: this.filterLogProp,
            filterOptions: {},
            filterTitles: uploadTitle,
            perviousFilterPayload: {},
            hasCrossFilter: this.$hasCrossFilter()
        }
    },
    activated() {
        this.hasCrossFilter = this.$hasCrossFilter()
        this.$nextTick(() => {
            this.resetFilter()
            if (this.hasCrossFilter) this.applyFilter()
        })
    }
}
</script>
